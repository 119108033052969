(function(window, document, undefined) {
'use strict';
// http://docs.ourcodeworld.com/projects/holdon-js/documentation/methods/open
var options = {
    theme : 'sk-folding-cube',
    message : 'Please wait...',
    // backgroundColor : '#1847B1',
    textColor : 'white'
};


$(function () {

    // use the stripe public (Publishable key) key pk_...
    var stripe = Stripe('pk_live_Q3LhkgidbJD0Al4l1J8630Ef');
    // var stripe = Stripe('pk_test_Zvf77IOqJPNe3MxQB5Vp06v2');
    var elements = stripe.elements();

    var card = elements.create('card', {
        style : {
            base : {
                iconColor : '#666EE8',
                color : '#31325F',
                lineHeight : '40px',
                fontWeight : 300,
                fontFamily : '"Helvetica Neue", Helvetica, sans-serif',
                fontSize : '15px',
                '::placeholder' : {
                    color : '#CFD7E0'
                }
            }
        }
    });
    card.mount('#card-element');

    card.addEventListener('change', function (event) {
        var displayError = document.getElementById('card-errors');
        if (event.error) {
            displayError.textContent = event.error.message;
        } else {
            displayError.textContent = '';
        }
    });


    // Create a token or display an error when the form is submitted.
    var form = document.getElementById('payment-form');
    form.addEventListener('submit', function (event) {
        event.preventDefault();

        HoldOn.open(options);

        var cardholder = document.getElementById('cardholder').value;

        stripe.createToken(card, {
            name : cardholder
        }).then(function (result) {
            if (result.error) {

                HoldOn.close();

                // Inform the user if there was an error
                var errorElement = document.getElementById('card-errors');
                errorElement.textContent = result.error.message;
            } else {
                // Send the token to your server
                stripeTokenHandler(result.token);
            }
        });
    });


    function stripeTokenHandler(token) {
        // Insert the token ID into the form so it gets submitted to the server
        var form = document.getElementById('payment-form');
        var hiddenInput = document.createElement('input');
        hiddenInput.setAttribute('type', 'hidden');
        hiddenInput.setAttribute('name', 'stripeToken');
        hiddenInput.setAttribute('value', token.id);
        form.appendChild(hiddenInput);

        // Submit the form
        form.submit();
    }

    function numberFormat(number) {
        return parseFloat(number.toFixed(2)).toLocaleString('de-DE');
    }

    function amount() {
        var amount = $('#amount').val();

        // var fee = (amount * 0.029).toFixed(2);
        // var toPay = (amount * 1.0029).toFixed(2);

        var pay = Math.round(Number((amount) * 100 + 25) / 0.971) / 100;
        var fee = pay - amount;

        // console.log(pay);

        $('#fee').html(numberFormat(fee) + ' EUR');

        $('#amountInfo').html('Amount you will be charged: ' + numberFormat(pay) + ' EUR');

        $('#button').html('Pay ' + numberFormat(pay) + ' EUR');


        // var feeElement = document.getElementById('fee');
        // feeElement.textContent = fee + ' EUR';
        //
        // var buttonElement = document.getElementById('button');
        // buttonElement.textContent = 'Pay ' + toPay + ' EUR';

        $('#toPay').val(pay);

        // var form = document.getElementById('payment-form');
        // var hiddenInput = document.createElement('input');
        // hiddenInput.setAttribute('type', 'hidden');
        // hiddenInput.setAttribute('name', 'toPay');
        // hiddenInput.setAttribute('value', toPay);
        // form.appendChild(hiddenInput);
    }

    $('#amount').keyup(function () {
        amount();
    });

    amount();

    function paymentFor() {
        console.log('check');
        var paymentFor = $('#paymentFor').val().split(' ');
        $('.perhaps-hidden').addClass('hidden');
        $('.select-' + paymentFor[0].toLowerCase()).removeClass('hidden');
    }

    $('#paymentFor').change(function () {
        paymentFor();
    });

    paymentFor();

});

})(window, document);
